<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Job Creator</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <img width="200" height="200" src="@/assets/images/user-placeholder.png"
                                     class="users-avatar-shadow rounded mb-2 pr-2 ml-1" alt="avatar">
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td>{{job_detail.user.name}}</td>
                                    </tr>

                                    <tr>
                                        <th>Email:</th>
                                        <td>{{job_detail.user.email}}</td>
                                    </tr>

                                    <tr>
                                        <th>Contact #</th>
                                        <td>{{job_detail.user.mobile_number}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Job Details</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Job Title:</th>
                                        <td>{{job_detail.title}}</td>
                                    </tr>
                                    <tr>
                                        <th>Job Status:</th>
                                        <td>{{job_detail.status ==='Cancelled' ? job_detail.status+" by "+job_detail.cancel_by: job_detail.status}}</td>
                                    </tr>
                                    <tr>
                                        <th>Job Type:</th>
                                        <td>{{job_detail.type == 1 ? "1-Day Booking": "Multiple day Booking"}}</td>
                                    </tr>

                                    <tr>
                                        <th>Start Time:</th>
                                        <td>{{getDateWithTimeZone(job_detail.start_time)}}</td>
                                    </tr>
                                    <tr>
                                        <th>End Time:</th>
                                        <td>{{getDateWithTimeZone(job_detail.end_time)}}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div class="col-sm-6">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Total Days:</th>
                                        <td>{{totalDays(job_detail)}}</td>
                                        <!--                      <td>{{job_detail.days}}</td>-->
                                    </tr>

                                    <tr>
                                        <th>Total Workers:</th>
                                        <td>{{job_detail.total_workers}}</td>
                                    </tr>

                                    <tr>
                                        <th>Currency:</th>
                                        <td>{{job_detail.currency}}</td>
                                    </tr>

                                    <tr>
                                        <th>Hourly Rate:</th>
                                        <td>{{job_detail.hourly_rate}}</td>
                                    </tr>

                                    <tr>
                                        <th>Job Description:</th>
                                        <td>{{job_detail.description}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-md-2">
                            <div class="col-sm-6">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Service:</th>
                                        <td>{{job_detail.employee_job_detail.service.title}}</td>
                                    </tr>

                                    <tr>
                                        <th>Fo Service:</th>
                                        <td>{{job_detail.employee_job_detail.fo_service.title}}</td>
                                    </tr>

                                    <tr>
                                        <th>Speciality:</th>
                                        <td>{{job_detail.employee_job_detail.speciality.title}}</td>
                                    </tr>

                                    <tr>
                                        <th>Sub Speciality:</th>
                                        <td>{{ShowSubSpeciality(job_detail.employee_job_detail.sub_speciality)}}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Job Workers</h4>
                        <p class="card-title-desc"></p>
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="workers"
                                    :fields="fields"
                                    responsive="sm"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                            >
                                <template v-slot:cell(status)="job">
                                    {{job.item.status === "Cancelled" ? job.item.status+" by "+job.item.cancel_by: job.item.status}}
                                </template>
                            </b-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Job Location</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-3">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>City:</th>
                                        <td>{{job_detail.location.city}}</td>
                                    </tr>
                                    <tr>
                                        <th>Street Address:</th>
                                        <td>{{job_detail.location.street_address}}</td>
                                    </tr>
                                    <tr>
                                        <th>postal_code:</th>
                                        <td>{{job_detail.location.postal_code}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-9">
                                <template>
                                    <gmap-map
                                            :center="{ lat: parseFloat(job_detail.location.latitude), lng: parseFloat(job_detail.location.longitude) }"
                                            :zoom="16" style="height: 500px">
                                        <GmapMarker
                                                :key="index"
                                                v-for="(m, index) in markers"
                                                :position="m"
                                                :clickable="true"
                                                :draggable="true"
                                                @click="center=m"
                                        />
                                    </gmap-map>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import moment from 'moment';


    export default {
        page: {
            title: "Jobs",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader},
        data() {
            return {
                color: '',
                job_detail: this.$route.params.job_details,
                workers: this.$route.params.job_details.job_request,
                title: "Jobs",
                items: [
                    {
                        text: "Any"
                    },
                    {
                        text: "Job"
                    },
                    {
                        text: this.$route.params.job_title
                    },
                ],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [

                        {key: "user.name", label: 'Name', sortable: false},
                        {key: "user.email", label: 'Email', sortable: false},
                        {key: "user.mobile_number", label: 'Contact #', sortable: false},
                        {key: "status", label: 'Job Status', sortable: true},
                    ],
                markers: [
                    {
                        lat: parseFloat(this.$route.params.job_details.location.latitude),
                        lng: parseFloat(this.$route.params.job_details.location.longitude),
                        label: this.$route.params.job_details.location.street_address
                    }
                ]
            };
        },
        computed: {},
        mounted() {
            if (!this.$route.params.job_details) {
                this.$router.push('/jobs')
            }
            console.log("job detail", this.job_detail.location);
        },
        created() {
        },

        methods: {
            getTimeZone() {
                return Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            getDateWithTimeZone(date) {
                return date;
                // let timeZone = this.getTimeZone();
                // let a = this.$moment.tz(date, timeZone);
                // let d = new Date(a._d);
                // let day = d.getDate().toString();
                // let getDate = day.length === 1 ? "0" + day : day
                // let month = (d.getMonth() + 1).toString();
                // let getMonth = month.length === 1 ? "0" + month : month
                // let seconds = (d.getSeconds()).toString();
                // let getSeconds = seconds.length === 1 ? "0" + seconds : seconds
                // let minutes = (d.getMinutes()).toString();
                // let getMinutes = minutes.length === 1 ? "0" + minutes : minutes
                // let hours = (d.getHours()).toString();
                // let getHours = hours.length === 1 ? "0" + hours : hours
                //
                // return d.getFullYear() + "-" + getMonth + "-" + getDate + " " +
                //     getHours + ":" + getMinutes + ":" + getSeconds;
            },
            ShowSubSpeciality(subSpeciality) {
                let data = "";
                subSpeciality.map(x => {
                    data += x.title + ","
                })
                return data.substring(0, data.length - 1);
            },
            totalDays(job) {
                let timeZone = this.getTimeZone()
                let startDate = moment.tz(job.start_time, timeZone).format('MM/DD/YYYY');
                let endDate = moment.tz(job.end_time, timeZone).format('MM/DD/YYYY');
                let future = moment(endDate);
                let start = moment(startDate);
                return future.diff(start, 'days') + 1;

            }
        }
    };
</script>
